@import '../../../index.css';
@import '../../../style.scss';

.torso {
  align-items: center;
  display: flex;
  height: 100%;
  flex-direction: column;

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    padding: 0 12px;

    &__title {
      white-space: pre-line;
      font-family: var(--modal-font, $modal-font);
      font-weight: 800;
      line-height: 24px;
    }

    &__description {
      white-space: pre-line;
      font-family: var(--modal-font, $modal-font);
      font-Weight: 400;
      padding: 0;
    }
  }

  &__body {
    display: flex;
  }

  &__buttons {
    display: flex;
    flex-direction: column;

    &__continue {
      position: relative;
      font-family: var(--modal-font, $modal-font);
      font-weight: 800;
      text-transform: none;
      background-color: var(--cta-color, $cta-color);
      height: 50px;
      border-radius: var(--cta-border-radius, $cta-border-radius);
      color: white;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      transition: opacity ease 0.2s;

      &:not(:disabled):hover {
        opacity: 0.8;
      }

      &:not(:disabled):hover &__icon {
        transition: all ease 0.2s;
        right: 30px;
      }

      &:disabled:hover &__icon {
        transition: all ease 0.2s;
        right: 40px;
      }

      &:not(:hover) &__icon {
        right: 40px;
        transition: all ease 0.2s;
      }

      &:disabled {
        color: white;
        background-color: #DDD;
      }

      &__icon {
        position: absolute;
      }
    }

    &__scan {
      position: relative;
      width: 100%;
      font-family: var(--modal-font, $modal-font);
      font-weight: 600;
      text-transform: none;
      background-color: white;
      border: 3px solid black;
      height: 50px;
      border-radius: var(--cta-border-radius, $cta-border-radius);
      color: black;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;

      &__icon {
        &.MuiSvgIcon-root {
          stroke: white;
          stroke-width: 1;
          font-size: 27px;
        }
      }

      &:hover &__icon {
        &.MuiSvgIcon-root {
          stroke: black;
        }
      }

      &:hover {
        background-color: black;
        color: white;

        &__icon {
          &.MuiSvgIcon-root {
            stroke: black;
          }
        }
      }

      &:disabled {
        color: white;
        background-color: #DDD;
      }
    }
  }

  @media only screen and (min-width: 1000px) {
    margin-top: 20px;

    &__text {
      margin-bottom: 40px;

      &__description {
        text-align: center;
        margin-top: 0;
        font-size: 16px;
      }
    }

    &__body {
      margin-bottom: 25px;
      gap: 25px;
    }

    &__stepper {
      margin-bottom: 30px;
    }

    &__buttons {
      width: 400px;
      gap: 10px;

      &__continue {
        font-size: 14px;
        height: 55px;
      }

      &__scan {
        font-size: 14px;
        height: 55px;
        gap: 5px;

        &__icon {
          &.MuiSvgIcon-root {
            font-size: 34px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 999px) {
    padding-top: 30px;

    &__text {
      margin-bottom: 15px;
      align-items: center;
      text-align: center;

      &__title {
        font-size: 20px;
      }

      &__description {
        font-size: 14px;
      }
    }

    &__body {
      margin-bottom: 15px;
      gap: 20px;
    }

    &__stepper {
      margin-bottom: 20px;
    }

    &__buttons {
      width: 350px;
      gap: 10px;

      &__continue {
        font-size: 12px;
        height: 50px;
      }

      &__scan {
        gap: 5px;
        font-size: 12px;
        height: 50px;

        &__icon {
          &.MuiSvgIcon-root {
            font-size: 29px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 374px) {
    padding-top: 30px;

    &__text {
      margin-bottom: 10px;
      align-items: center;
      text-align: center;

      &__title {
        font-size: 18px;
      }

      &__description {
        font-size: 12px;
      }
    }

    &__body {
      margin-bottom: 10px;
      gap: 10px;
    }

    &__stepper {
      margin-bottom: 15px;
    }

    &__buttons {
      width: 300px;
      gap: 10px;

      &__continue {
        font-size: 10px;
        height: 45px;
      }

      &__scan {
        gap: 3px;
        font-size: 10px;
        height: 45px;

        &__icon {
          &.MuiSvgIcon-root {
            font-size: 27px;
          }
        }
      }
    }
  }
}

.torso_choice {
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.06);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: var(--modal-font, $modal-font);
  font-weight: 500;
  text-transform: none;
  color: black;
  border: 1px solid transparent;

  &:hover {
    background-color: rgb(0,0,0,0.02);
    border: 1px solid #000;
    font-weight: 800;
  }

  &--selected {
    font-weight: 800;
    background-color: rgb(0,0,0,0.02);
    border: 1px solid #000;
  }

  &__loading {
    width: auto;
  }

  &__image {
    display: inline;
    width: auto;
    object-fit: contain;
    border-radius: 5px;
    transition: 0.3s;

    &--loading {
      display: none;
    }
  }

  @media only screen and (min-width: 1000px) {
    width: 130px;
    height: 190px;
    font-size: 12px;

    &__loading {
      margin-bottom: 20px;
      height: 130px;
    }

    &__image {
      margin-bottom: 20px;
      height: 130px;
    }
  }

  @media only screen and (max-width: 999px) {
    width: 90px;
    height: 130px;
    font-size: 12px;

    &__loading {
      margin-bottom: 10px;
      height: 90px;
    }

    &__image {
      margin-bottom: 10px;
      height: 90px;
    }
  }

  @media only screen and (max-width: 374px) {
    width: 80px;
    height: 110px;
    font-size: 9px;

    &__loading {
      margin-bottom: 10px;
      height: 70px;
    }

    &__image {
      margin-bottom: 10px;
      height: 70px;
    }
  }
}
