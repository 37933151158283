@import '../../../../index.css';
@import '../../../../style.scss';

.intro-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 30px;
  color: white;

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    padding: 0 12px;

    &__title {
      white-space: pre-line;
      font-family: var(--modal-font, $modal-font);
      font-weight: 800;
      line-height: 24px;

      &__unique-gender {
        width: 100%;
        font-size: 20px;
        text-align: center;
        font-family: var(--modal-font, $modal-font);
        font-weight: 800;
        white-space: pre-line;
        font-family: var(--modal-font, $modal-font);
      }
    }

    &__description {
      white-space: pre-line;
      font-family: var(--modal-font, $modal-font);
      font-Weight: 400;
      padding: 0;
    }
  }

  &__buttons {
    align-self: center;
    display: flex;
    flex-direction: column;

    &__button {
      &__light, &__dark {
        font-family: var(--modal-font, $modal-font);
        font-weight: 400;
        text-transform: none;
        border-radius: var(--cta-border-radius, $cta-border-radius);
        background-color: rgba(255, 255, 255, 0.5);
        color: var(--intro-text-color);
        &:hover {
          border: 0;
          background-color: var(--intro-text-color);
        }
        &--active {
          font-family: var(--modal-font, $modal-font);
          font-weight: 400;
          text-transform: none;
          border-radius: var(--cta-border-radius, $cta-border-radius);
          background-color: var(--intro-text-color);
          border: 0;
        }
      }
      &__light{
        &:hover{
          color: black;
        }
        &--active{
          color: black;
        }
      }
      &__dark{
        &:hover{
          color: white;
        }
        &--active{
          color: white;
        }
      }
    }

    &__continue {
      &__light, &__dark{
        position: absolute;
        bottom: 0;
        font-family: var(--modal-font, $modal-font);
        font-size: 14px;
        font-weight: 600;
        text-transform: none;
        border-radius: var(--cta-border-radius, $cta-border-radius);
        background-color: var(--intro-text-color);
        margin-bottom: 12px;
        transition: opacity ease 0.2s;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        &:hover {
          opacity: 0.8;
        }
        &:disabled {
          color: white;
          background-color: rgb(224,224,224,0.8);
        }
      }
      
      &__light{
        background-color: white;
        color: black;
      }
      &__dark{
        background-color: black;
        color: white;
      } 
      &__label {
        width: 80%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media only screen and (max-width: 999px) {
    gap: 70px;

    &__text {
      &__title {
        font-size: 20px;
      }

      &__description {
        font-size: 14px;
      }
    }

    &__buttons {
      gap: 15px;

      &__button__light, &__button__dark {
        font-size: 16px;
        width: 275px;
        height: 50px;
        &--active{
          font-size: 16px;
          width: 275px;
          height: 50px;
        }
      }

      &__continue {
        &__light, &__dark{
          width: 350px;
          font-size: 15px;
          height: 50px;
        }
      }
    }
  }

  @media only screen and (max-width: 374px) {
    gap: 50px;

    &__text {
      &__title {
        font-size: 18px;
      }

      &__description {
        font-size: 12px;
      }
    }

    &__buttons {
      gap: 10px;

      &__button__light, &__button__dark {
        font-size: 14px;
        width: 250px;
        height: 45px;
        &--active{
          font-size: 14px;
          width: 250px;
          height: 45px;
        }
      }

      &__continue {
        &__light, &__dark{
          width: 300px;
          font-size: 10px;
          height: 45px;
        }
      }
    }
  }
}
