@import '../../../../style.scss';
@import '../../../../style.scss';

.inputfield {
  display: flex;
  flex-direction: column;
  margin: 20px 50px 20px 50px;
  margin-bottom: auto;
  width: 100%;

  &__label {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    &__text {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      font-family: var(--modal-font, $modal-font);
      font-weight: 700;
      color: black;

      &--error {
        color: red;
      }
    }

    &__children {
      width: auto;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      background-color: #f4f4f4;
      border-radius: 20px;
    }
  }

  &__textfield {
    width: 100%;
    margin-right: 10px;

    & .MuiInputBase-input {
      font-family: var(--modal-font, $modal-font);
      font-size: 16px;
      font-weight: 400;
      color: black;
      caret-color: black;
    }

    & .MuiInputLabel-root {
      line-height: inherit;
      top: -12px;
    }

    & .MuiOutlinedInput-root {
      height: 100%;

      & fieldset {
        border-radius: 0;
        border: 1px black solid;
      }

      &:hover fieldset {
        border-radius: 0;
        border-width: 2px;
      }

      &.Mui-focused fieldset {
        border-radius: 0;
        border: 2px black solid;
      }
    }

    & .MuiFormHelperText-root {
      font-family: var(--modal-font, $modal-font);
      font-size: 9px;
      font-weight: 400;
      margin: 0;
      margin-top: 3px;
    }

    &--error {
      & .MuiInputBase-input {
        color: red;
        caret-color: red;
      }

      & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
          border: 2px red solid;
        }
      }
    }
  }

  @media only screen and (max-width: 999px) {
    &__label {
      &__text {
        font-size: 15px;
      }
    }

    &__textfield {
      height: 50px;

      /*& .MuiInputBase-input {
        font-size: 13px;
      }*/
    }
  }

  @media only screen and (max-width: 374px) {
    &__label {
      &__text {
        font-size: 14px;
      }
    }

    &__textfield {
      height: 40px;

      /*& .MuiInputBase-input {
        font-size: 12px;
      }*/
    }
  }
}
