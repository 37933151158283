@import '../../../../index.css';
@import '../../../../style.scss';

.qrcode_desktop {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: row;

  &__video {
    display: flex;
    flex-direction: column;
    height: 480px;
    min-width: 400px;
    position: relative;
    margin-left: 30px;

    video {
      height: 400px;
      width: 400px;
    }

    &__help {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      text-transform: none;
      text-decoration: underline;
      text-align: center;
      font-family: var(--modal-font, $modal-font);
      font-weight: 400;
      color: black;
      font-size: 14px;

      &:hover {
        background-color: white;
      }

      &__icon {
        height: auto;
        width: 18px;
      }
    }

    &__copyright {
      height: fit-content;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 13px;

      p {
        color: rgba(0, 0, 0, 0.7);
        font-family: var(--modal-font, $modal-font);
        font-size: 10px;
        font-weight: 300;
        text-align: center;
        margin-bottom: 3px;
        cursor: default;
      }

      img {
        width: 40px;
        height: auto;
      }
    }
  }

  &__body {
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 480px;
    padding-top: 50px;
    margin-left: 20px;

    &__text {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      padding-right: 40px;

      &__title {
        white-space: pre-line;
        font-family: var(--modal-font, $modal-font);
        font-size: 26px;
        font-weight: 700;
        line-height: 33px;
      }

      &__description {
        white-space: pre-line;
        font-family: var(--modal-font, $modal-font);
        font-size: 18px;
        font-weight: 500;
        text-align: justify;
        line-height: 20px;
        margin: 5px 0 20px 0;
      }
    }

    &__banner {
      display: flex;
      flex-direction: row;
      width: 100%;
      box-shadow: 0px 0px 16px 2px rgba(0,0,0,0.1);
      border-radius: 10px 0 0 10px;
      height: 140px;
      align-items: center;
      gap: 40px;

      &__qrcode {
        padding: 10px 0;
        margin-left: 10px;
        transition: 0.3s;
      }

      &__qrcode_animation {
        width: 120px;
        height: auto;
      }

      &__text {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;

        &__title {
          color: black;
          font-family: var(--modal-font, $modal-font);
          font-size: 18px;
          font-weight: 800;
          text-transform: uppercase;
          line-height: 15px;
          margin-bottom: 15px;
        }

        &__description {
          white-space: pre-line;
          color: black;
          font-family: var(--modal-font, $modal-font);
          font-size: 16px;
          font-weight: 400;
          line-height: 15px;
        }
      }
    }

    &__skip {
      font-family: var(--modal-font, $modal-font);
      font-weight: 500;
      font-size: 14px;
      padding: 0;
      text-align: left;
      background-color: none;
      color: #898989;
      text-transform: none;
      text-decoration: underline;
      position: absolute;
      bottom: 15px;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
