@import '../../../../index.css';
@import '../../../../style.scss';

.inputslider {
  display: flex;
  flex-direction: column;
  margin: 10px 50px 10px 50px;
  width: 500px;
  margin-bottom: auto;

  &__label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 20px;

    &__text {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      font-family: var(--modal-font, $modal-font);
      font-size: 16px;
      font-weight: 700;
      color: black;

      &--error {
        color: red;
      }
    }

    &__children {
      width: auto;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      background-color: #f4f4f4;
      border-radius: 20px;
    }
  }

  &__slider {
    &.MuiSlider-root {
      color: black;

      & .MuiSlider-track {
        color: black;
      }

      & .MuiSlider-thumb {
        color: black;
      }

      & .MuiSlider-markLabel {
        font-size: 12px;
      }
    }
  }
}
