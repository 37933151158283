@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neue/HelveticaNeueThin.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neue/HelveticaNeueLight.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neue/HelveticaNeueRegular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neue/HelveticaNeueItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neue/HelveticaNeueBold.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
