.stepper {
  display: flex;
  flex-direction: row;

  &__step {
    border-radius: 50%;
    background-color: #D9D9D9;

    &--active {
      background-color: black;
    }
  }

  @media only screen and (min-width: 1000px) {
    gap: 10px;

    &__step {
      height: 7px;
      width: 7px;
    }
  }

  @media only screen and (max-width: 999px) {
    gap: 9px;

    &__step {
      height: 6px;
      width: 6px;
    }
  }

  @media only screen and (max-width: 374px) {
    gap: 8px;

    &__step {
      height: 5px;
      width: 5px;
    }
  }
}
