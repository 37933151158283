.kleep-modal {
  &__flag-icons {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    line-height: 1em;
    height: 1em;
    width: 19px;
    height: 12px;

    &::before {
      content: " ";
    }

    &.FR {
      background-image: url(../../assets/breasts/FR.svg);
    }
    &.GB {
      background-image: url(../../assets/breasts/GB.svg);
    }
    &.DE {
      background-image: url(../../assets/breasts/DE.svg);
    }
    &.IT {
      background-image: url(../../assets/breasts/IT.svg);
    }
  }
}
