@import '../../../index.css';
@import '../../../style.scss';

.result_loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__progress {
    position: relative;
    display: inline-flex;
    width: fit-content;

    &__loader {
      position: relative;

      &__circle {
        &.MuiCircularProgress-root {
          color: rgba(0, 0, 0, 0.10);
        }
      }

      &__circle_loader {
        position: absolute;
        left: 0;

        &.MuiCircularProgress-root {
          color: black;
        }
      }
    }

    &__label {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: var(--modal-font, $modal-font);
      font-weight: 700;
    }
  }

  &__text {
    &__description {
      margin: 10px 0;
      text-align: center;
      font-family: var(--modal-font, $modal-font);
      font-weight: 700;
    }

    &__tips {
      text-align: center;
      font-family: var(--modal-font, $modal-font);
      font-size: 14px;
      font-weight: 400;
    }
  }

  @media only screen and (min-width: 1000px) {
    &__progress {
      &__label {
        font-size: 55px;
      }
    }

    &__text {
      &__description {
        font-size: 28px;
      }

      &__tips {
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 999px) {
    margin: 40px 0;

    &__progress {
      &__label {
        font-size: 45px;
      }
    }

    &__text {
      padding: 0 30px;

      &__description {
        font-size: 26px;
      }

      &__tips {
        font-size: 13px;
      }
    }
  }

  @media only screen and (max-width: 374px) {
    margin: 40px 0;

    &__progress {
      &__label {
        font-size: 45px;
      }
    }

    &__text {
      padding: 0 30px;

      &__description {
        font-size: 24px;
      }

      &__tips {
        font-size: 12px;
      }
    }
  }
}
