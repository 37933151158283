@import '../../../../index.css';
@import '../../../../style.scss';

.intro-desktop {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  width: 100%;
  height: 100%;
  color: var(--intro-text-color, $intro-text-color);

  &__block {
    width: 80%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 40px;
    width: 100%;
    height: 100%;

    &__title {
      white-space: pre-line;
      font-family: var(--modal-font, $modal-font);
      font-weight: 800;
      text-align: center;
      letter-spacing: 0.5px;
      line-height: 25px;
      font-size: 22px;
    }

    &__description {
      white-space: pre-line;
      font-family: var(--modal-font, $modal-font);
      font-Weight: 400;
      text-align: center;
      padding: 0;
      font-size: 16px;
      line-height: 19px;
      margin: 16px 0 15px 0;
    }

    &__buttons {
      align-self: center;
      display: flex;
      flex-direction: row;
      gap: 30px;

      &__button {
        &__light, &__dark {
          font-family: var(--modal-font, $modal-font);
          font-weight: 400;
          text-transform: none;
          height: 55px;
          border-radius: var(--cta-border-radius, $cta-border-radius);
          background-color: rgba(255, 255, 255, 0.2);
          color: var(--intro-text-color, $intro-text-color);
          border: 2px solid var(--intro-text-color, $intro-text-color);
          font-size: 18px;
          width: 180px;

          &:hover {
            border: 0;
            background-color: var(--intro-text-color, $intro-text-color);
          }

          &--active {
            font-family: var(--modal-font, $modal-font);
            font-weight: 400;
            text-transform: none;
            height: 55px;
            border-radius: var(--cta-border-radius, $cta-border-radius);
            font-size: 18px;
            width: 180px;
            background-color: var(--intro-text-color, $intro-text-color);
            border: 0;
          }
        }
        &__light{
          &:hover{
            color: black;
          }
          &--active{
            color: black;
          }
        }
        &__dark{
          &:hover{
            color: white;
          }
          &--active{
            color: white;
          }
        }

      }

      &__continue {
        &__light, &__dark{
        font-family: var(--modal-font, $modal-font);
        font-size: 14px;
        font-weight: 600;
        text-transform: none;
        height: 50px;
        width: 335px;
        border-radius: var(--cta-border-radius, $cta-border-radius);
        margin-top: 20px;
        transition: opacity ease 0.2s;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

          &:hover {
            opacity: 0.8;
          }

          &:disabled {
            color: white;
            background-color: rgb(224,224,224,0.8);
          }
        }

        &__light{
          background-color: white;
          color: black;
        }
        &__dark{
          background-color: black;
          color: white;
        }      
        &__label {
          width: 80%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
